import firebase from 'firebase';
var firebaseConfig = {
  apiKey: "AIzaSyBDP8PUkK22YW0CT4Gs_qThz2Oa58Q4Nnw",
  authDomain: "tradingdirectexpress-cf9ff.firebaseapp.com",
  databaseURL: "https://tradingdirectexpress-cf9ff-default-rtdb.firebaseio.com",
  projectId: "tradingdirectexpress-cf9ff",
  storageBucket: "tradingdirectexpress-cf9ff.firebasestorage.app",
  messagingSenderId: "1026015588944",
  appId: "1:1026015588944:web:e0406b3a01e37e2cea9755"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.firestore().settings({timestampsInSnapshots:true})

  export const f=firebase;
  export const database=firebase.database();
  export const storage=firebase.storage();
  export const auth=firebase.auth();
  export default firebase.firestore()