import React, { useState } from "react";

function Navbar({ isVisible, activePage }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navItems = [
    { name: "Account", href: "dashboards", icon: "fa-home" },
    { name: "Deposit", href: "deposit", icon: "fa-money" },
    { name: "Withdraw", href: "withdraw", icon: "fa-credit-card" },
    { name: "Transactions", href: "transaction", icon: "fa-exchange" },

    { name: "History", href: "history", icon: "fa-exchange" },
    { name: "Signal Purchase", href: "signal", icon: "fa-signal" },
    { name: "Account Upgrade", href: "upgrade", icon: "fa-bank" },
    
    { name: "Settings", href: "settings", icon: "fa-cog" },
    { name: "Support", href: "mailto:support@trad.com", icon: "fa-life-ring" },
  ];

  const coinPlatforms = [
    { name: "Luno", href: "https://www.luno.com" },
    { name: "Remitano", href: "https://www.remitano.com" },
    { name: "Moonpay", href: "https://www.moonpay.io" },
    { name: "Local Bitcoin", href: "https://www.localbitcoins.com" },
    { name: "Paxful", href: "https://www.payful.com" },
    { name: "Coinbase", href: "https://www.coinbase.com" },
    { name: "Ramp", href: "https://www.ramp.network/buy" },
    { name: "Banxa", href: "https://openocean.banxa.com" },
    { name: "Chainbits", href: "https://www.chainbits.com" },
    { name: "Bitcoin", href: "https://www.bitcoin.com" },
    { name: "Coinmama", href: "https://www.coinmama.com" },
  ];

  return (
    <div
      className="sidebar"
      style={{
        position: "fixed",
        top: 0,
        left: isVisible ? 0 : "-250px", // Slide in/out effect
        width: "250px",
        height: "100%",
        backgroundColor: "#222",
        color: "#fff",
        overflowY: "auto",
        transition: "left 0.3s ease",
        padding: "15px",
        zIndex: 1000, // Keeps it on top of most elements but below the header
      }}
    >
      <div className="user-menu-items" style={{ marginBottom: "15px" }}>
        <div className="list-unstyled btn-group">
          <button
            className="media btn btn-link"
            aria-haspopup="true"
            aria-expanded="false"
            style={{
              display: "flex",
              alignItems: "center",
              background: "none",
              border: "none",
              color: "#fff",
              textAlign: "left",
            }}
          >
            <span className="message_userpic">
              <img
                className="d-flex"
                src="https://img.icons8.com/?size=80&id=108652&format=png"
                alt="Generic user"
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  marginRight: "10px",
                }}
              />
            </span>
            <span className="media-body">
              <span style={{ fontWeight: "bold" }}>official</span>
            </span>
          </button>
        </div>
      </div>

      <ul
        className="nav flex-column"
        style={{ listStyle: "none", padding: 0, margin: 0 }}
      >
        {navItems.map((item) => (
          <li className="nav-item" style={{ marginBottom: "10px" }} key={item.href}>
            <a
              className="nav-link"
              href={item.href}
              style={{
                color: "#fff",
                textDecoration: "none",
                padding: "10px",
                borderRadius: "5px",
                background: activePage === item.name ? "linear-gradient(to right, #4facfe, #00f2fe)" : "none",
                fontWeight: activePage === item.name ? "bold" : "normal",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className={`fa ${item.icon}`} style={{ marginRight: "10px" }} />
              {item.name}
            </a>
          </li>
        ))}

        {/* Dropdown Toggle */}
        <li className="nav-item">
          <button
            className="nav-link"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            style={{
              color: "#fff",
              textDecoration: "none",
              padding: "10px",
              borderRadius: "5px",
              background: "none",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              border: "none",
              cursor: "pointer",
            }}
          >
            <span>
              <i className="fa fa-angle-down" style={{ marginRight: "10px" }} />
              Where to Buy Coin
            </span>
            <i
              className={`fa ${isDropdownOpen ? "fa-angle-up" : "fa-angle-down"}`}
              style={{ marginLeft: "auto" }}
            />
          </button>
          {isDropdownOpen && (
            <ul style={{ listStyle: "none", padding: "10px", margin: 0 }}>
              {coinPlatforms.map((platform) => (
                <li key={platform.name} style={{ marginBottom: "5px" }}>
                  <a
                    className="nav-link"
                    href={platform.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                      padding: "5px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i className="fas fa-coins" style={{ marginRight: "10px" }} />
                    {platform.name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Navbar;




{/* <div className="sidebar-left">
<div className="user-menu-items">
  <div className="list-unstyled btn-group">
    <button
      className="media btn btn-link"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span className="message_userpic">
        <img
          className="d-flex"
          src="https://img.icons8.com/?size=80&id=108652&format=png"
          alt="Generic user image"
        />
      </span>
      <span className="media-body">
        <span className="mt-0 mb-1">officialmarvez</span> <small />
      </span>
    </button>
  </div>
</div>
<br />
<ul className="nav flex-column in" id="side-menu">
  <li className="nav-item">
    <a className="nav-link" href="dashboards">
      <i className="fa fa-home" />
      Account
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="deposit">
      <i className="fa fa-money" />
      Deposit
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="withdraw">
      <i className="fa fa-briefcase" />
      Withdraw
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="history">
      <i className="fa fa-exchange" />
      History
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="transaction">
      <i className="fa fa-exchange" />
      Transactions
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="signal">
      <i className="fa fa-signal" />
      Signal Purchase
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="upgrade">
      <i className="fa fa-bank" />
      Account Upgrade
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="settings">
      <i className="fa fa-gear" />
      Account Settings
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="mailto:support@tradingdirectexpress.com">
      <i className="fa fa-comment" />
      Contact Support
    </a>
  </li>
  <li className="nav-item">
    <a href="javascript:void(0)" className="menudropdown nav-link">
      Where to Buy Coin
      <i className="fa fa-angle-down" />
    </a>
    <ul className="nav flex-column nav-second-level">
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.luno.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Luno
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.remitano.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Remitano
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.moonpay.io"
          target="_blank"
        >
          <i className="fas fa-coins" /> Moonpay
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.localbitcoins.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Local Bitcoin
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.payful.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Paxful
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.coinbase.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Coinbase
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.ramp.network/buy"
          target="_blank"
        >
          <i className="fas fa-coins" /> Ramp
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://openocean.banxa.com"
          target="_blank"
        >
          <i className="fas fa-coins" />
          Banxa
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.chainbits.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Chainbits
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.bitcoin.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Bitcoin
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          href="https://www.coinmama.com"
          target="_blank"
        >
          <i className="fas fa-coins" /> Coinmama
        </a>
      </li>
    </ul>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="logout">
      <i className="fa fa-power-off" />
      Logout
    </a>
  </li>
</ul>
<hr />
<ul className="nav flex-column in">
  <li className="nav-item">
    <div className="nav-link">
      <h5>
        <span>2024/11/16 10:29am</span>
      </h5>
    </div>
  </li>
  <li className="nav-item" />
</ul>
<br />
<br />
</div> */}